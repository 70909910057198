<template>
  <div class="fill-height">
    <page-offset />
<div class="vertical-line"></div>
    <v-row class="options" no-gutters dense align="stretch">
      <v-col>
        <v-container class="option" color="grey">
          <img :src="require('@/assets/new/interactive.svg')" class="interactive-icon"/>
          <div class="option-label display-2">INTERACTIVE</div>
          <div class="options-container">
              <div class="option-label-description">BE A PART OF THE LIVE SHOW</div>
              <v-btn outlined class="no-border" width="200px"  to="/tickets/purchase">
                <img :src="require('@/assets/new/arrow.svg')" class="interactive-icon"/>
              </v-btn>            
          </div>
        </v-container>
      </v-col>

      <v-col>
        <v-container class="option">
          <img :src="require('@/assets/new/spectator.svg')" class="spectator-icon"/>
          <div class="option-label display-2">SPECTATOR</div>
          <div class="options-container">
              <div class="option-label-description">WATCH A LIVE STREAM OF THE SHOW</div>
              <v-btn class="no-border" outlined width="200px" to="/tickets/spectator">
                <img :src="require('@/assets/new/arrow.svg')" class="interactive-icon"/>
              </v-btn>  
          </div>

        </v-container>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import PageOffset from '../layout/PageOffset.vue';


export default {
  async mounted() {
    this.hide=true;
  },

  components: { PageOffset }

};
</script>

<style lang="scss" scoped>
.options {
  height: 100%;
}

.no-border{
  border:none!important;
}
.interactive-icon{
  width: auto;
}
.spectator-icon{
  width: auto;
  margin-bottom:57px;
}

.vertical-line{
    position: absolute;
    height: 50%;
    width: 1px;
    border-right: 1px solid rgba(255,255,255,.2);
    top: 50%;
    left: 50%;
    transform: translate(1px, -50%);
}

// .options > *:first-child {
//   background-color: rgba(238,238,238,.3);
// }

.options-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 60%;
}

.option {
  height: 100%;

  display: flex;
  flex-flow: column nowrap;

  justify-content: center;
  align-items: center;
}

.option-label {
  padding: 1rem 1rem 0;
  font-weight: 600;
  font-size: 18px!important;
  letter-spacing: 4.6px!important;
}

.option-label-description {
  font-style: normal;
  font-weight: 200;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 2.7px;
  color: #FFFFFF;
}

.options .v-btn {
  margin-top:3em;
  height: 42px;
}

footer{
  display:none!important;
}

@media screen and(max-width:850px) {
    .options{
        flex-direction: column;
        justify-content: center;
        .col{
            padding: 0;
            max-height: 450px; 
            padding: 0 30px;
        }
        .option.color{
              border-bottom: 1px solid;

        }

        
            .options-container{
                max-width:100%!important;
                img{
                      min-width: 110px;

                }
            }
    }  

    .vertical-line{
      display:none;
    }
    .fill-height{
      padding-top:90px!important;
    }
    
}

</style>
